<template>
  <div class="d-flex flex-column min-vh-100 overflow-visible">
    <div class="my-auto mx-auto justify-content-between align-item-center">
      <div class="my-4">
        <router-link :to="{ name: 'wrist' }">
          <button
            class="btn btn-primary impairex-button rounded-pill text-white"
          >
            Wrist
          </button>
        </router-link>
      </div>

      <div class="my-4 row">
        <div class="col">
          <button
            class="btn btn-primary impairex-button rounded-pill text-white"
            type="button"
            @click="allowMorePartsMenu = true"
          >
            Finger
          </button>
        </div>
        <div class="col">
          <div v-if="allowMorePartsMenu === true" class="dropdown droptop">
            <button
              id="handDropdown"
              class="btn btn-primary impairex-button rounded-pill text-white dropdown-toggle"
              data-bs-toggle="dropdown"
              data-bs-offset="[0,20]"
              aria-expanded="false"
            >
              select your parts
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="handDropdown"
            >
              <li
                v-for="(parts, index) in handParts"
                :key="index"
                class="form-check m-3"
              >
                <input
                  v-model="selectedParts"
                  :value="parts"
                  class="form-check-input"
                  type="checkbox"
                />
                {{ parts }}
              </li>
            </ul>
          </div>
        </div>
        <div v-if="selectedParts.length > 0" class="col">
          <button
            class="btn btn-success impairex-button rounded-pill text-white"
            @click="submitParts"
          >
            Next
          </button>
        </div>
      </div>

      <div class="my-4">
        <router-link :to="{ name: 'upper-body-parts' }">
          <button
            class="btn btn-primary impairex-button rounded-pill text-white"
          >
            Elbow
          </button>
        </router-link>
      </div>
      <div class="my-4">
        <router-link :to="{ name: 'shoulder' }">
          <button
            class="btn btn-primary impairex-button rounded-pill text-white"
          >
            Shoulder
          </button>
        </router-link>
      </div>
      <div v-show="selectedParts.length > 0" class="card p-4">
        <p>Selected:</p>
        <p v-for="(part, index) in selectedParts" :key="index">
          {{ part }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allowMorePartsMenu: false,
      handParts: [
        'Thumb',
        'Index Finger',
        'Middle Finger',
        'Ring Finger',
        'Little Finger',
      ],
      selectedParts: [],
    }
  },
  methods: {
    submitParts() {
      this.$store.commit('STORE_BODYPARTS', this.selectedParts)
      this.$router.push({ name: 'fingers' })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  z-index: 999;
}
</style>
